import React, { useState, useEffect } from "react";
import axios from "axios";
import './timeline.css';

import Icon from '../../images/title-1.webp';
import Arrow from '../../images/arrow.webp';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Timeline = () => {

    const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
    const [milestoneSetting, setMilestoneSetting] = useState({});
    const [milestoneList, setMilestone] = useState({});
    const [milestoneLoading, setMilestoneLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const milestoneSettingsApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/milestone"
                );

                const milestoneSetting = data.data.milestone || {};
                setMilestoneSetting(milestoneSetting);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setMilestoneLoading(false);
            }
        };
        milestoneSettingsApi();
    }, []);

    useEffect(() => {
        const milestoneApi = async () => {
            try {
                const { data } = await axios.get(
                    "https://backend.groups.abreco.nvds.in/api/milestone-list"
                );

                const milestoneList = data.data.milestone_list || {};
                setMilestone(milestoneList);

            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };
        milestoneApi();
    }, []);

    if (milestoneLoading || loading) {
        return <div></div>;
    }

    const items = milestoneList.map((list, index) => (
        <div className="mbox" key={index}>
            <div class="myear">{list.year}</div>
            <div className="mdata">
                <img src={baseUrl + list.logo} alt="Abreco Group" />
                <span className="step-title">{list.title}</span>
                <small className="step-description" dangerouslySetInnerHTML={{ __html: list.description }}></small>
            </div>
        </div>
    ));

    const responsive = {
        0: { items: 1 },
        768: { items: 2 },
        1014: { items: 2 },
        1200: { items: 4 },
        1368: { items: 4 },
        1600: { items: 5 },
    };

    return (
        <>
            {/* <section className='pageTitle timeline'>
                <div className='container'>
                    <div className='data' data-aos="fade-up">
                        <div className="dots dot-pattern-ten-six xl">
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        </div>
                        {milestoneSetting?.title ? (
                            <>
                                <p dangerouslySetInnerHTML={{ __html: milestoneSetting.title }}></p>
                                <h2 dangerouslySetInnerHTML={{ __html: milestoneSetting.sub_title }}></h2>
                                <small dangerouslySetInnerHTML={{ __html: milestoneSetting.content }}></small>
                            </>
                        ) : (
                            <p></p>
                        )}
                    </div>
                    <div className="steps-timeline">
                        {milestoneList.map((list, index) => (
                            <>
                                <div className="step" key={index}>
                                    <div className="line" data-aos="fade-up"></div>
                                    <div className="step-milestone">{list.year}</div>
                                    <div className="stepData" data-aos="fade-up">
                                        <img src={baseUrl + list.logo} alt="Abreco Group" />
                                        <span className="step-title">{list.title}</span>
                                        <p className="step-description" dangerouslySetInnerHTML={{ __html: list.description }}></p>
                                    </div>
                                </div>
                            </>
                        ))}
                    </div>
                </div>
            </section> */}

            <section className='pageTitle aliceTimeline'>
                <div className='container'>
                    <div className='data mb-4' data-aos="fade-up">
                        <div className="dots dot-pattern-ten-six xl">
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                            <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                        </div>
                        {milestoneSetting?.title ? (
                            <>
                                <p dangerouslySetInnerHTML={{ __html: milestoneSetting.title }}></p>
                                <h2 dangerouslySetInnerHTML={{ __html: milestoneSetting.sub_title }}></h2>
                                <small dangerouslySetInnerHTML={{ __html: milestoneSetting.content }}></small>
                            </>
                        ) : (
                            <p></p>
                        )}
                    </div>
                    <AliceCarousel
                        mouseTracking
                        items={items}
                        autoPlay
                        autoPlayInterval={2000}  // Interval in milliseconds (default is 3000)
                        infinite
                        animationType="slide"
                        animationEasingFunction="ease"
                        disableDotsControls
                        animationDuration={1000}  // Duration in milliseconds
                        autoPlayStrategy="none"
                        stopOnHover={false}
                        responsive={responsive}                        
                    />
                </div>
            </section>
        </>
    )
}

export default Timeline