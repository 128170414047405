import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import SimpleAnimatedComponent from '../../components/SimpleAnimatedComponent';
import Footer from "../../components/footer/Footer";
import Ceo from '../../components/ceo/Ceo';
import Clients from '../../components/clients/Clients';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import './about.css';

const AboutUs = () => {

  const baseUrl = "https://backend.groups.abreco.nvds.in/storage/";
  const [aboutSlider, setAboutSlider] = useState({});
  const [mission, setMission] = useState({});
  const [vision, setVision] = useState({});
  const [values, setValues] = useState({});
  const [impact, setImpact] = useState({});
  const [counterList, setCounterList] = useState({});
  const [impactList, setImpactList] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const aboutSliderApi = async () => {
      try {
        const { data } = await axios.get(
          "https://backend.groups.abreco.nvds.in/api/about-us"
        );

        const aboutSlider = data.data.about || {};
        setAboutSlider(aboutSlider);

        const mission = data.data.mission || {};
        setMission(mission);
        setVision(vision);
        setValues(values);

        const impact = data.data.impact_setting || {};
        setImpact(impact);
        const impactList = data.data.impact_list || {};
        setImpactList(impactList);
        
        const counterList = data.data.counter || {};
        setCounterList(counterList);

      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setLoading(false);
      }
    };
    aboutSliderApi();
  }, []);

  if (loading) {
    return <div></div>;
  }

  const impactItems = impactList.map((list, index) => (
    <div className="item" key={index}>
      <img src={baseUrl + list.image} alt="Abreco Group" />
      <h4>{list.title}</h4>
      <p dangerouslySetInnerHTML={{ __html: list.content }}></p>
    </div>
  ));

  const responsive = {
    0: { items: 1 }, 568: { items: 1 }, 1024: { items: 1 }, 1200: { items: 1 }, 1368: { items: 1 },
  };

  const itemsa = [
    <div className="item">
      <img src={baseUrl + aboutSlider.image} alt="Abreco Group" />
      <div className="slide-content" data-aos="fade-up"><h2 dangerouslySetInnerHTML={{ __html: aboutSlider.title }}></h2><span>{aboutSlider.sub_title}</span></div>
    </div>
  ];

  const responsivea = {
    0: { itemsa: 1 },
    568: { itemsa: 1 },
    1024: { itemsa: 1 },
    1200: { itemsa: 1 },
    1368: { itemsa: 1 },
  };

  // COUNTER UP WHEN SCROLL TO THE SECTION START
  function Counter({ start, end, duration, delay = 0, prefix = '', suffix = '' }) {
    const [count, setCount] = useState(start);
    const [hasStarted, setHasStarted] = useState(false);
    const counterRef = useRef(null);
    const steps = Math.ceil(duration * 1000 / 100); // number of updates, assuming 100ms interval
    const increment = (end - start) / steps; // value to add in each update

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setHasStarted(true);
            observer.unobserve(counterRef.current); // Stop observing once started
          }
        },
        { threshold: 0.1 } // Adjust as needed
      );

      if (counterRef.current) {
        observer.observe(counterRef.current);
      }

      return () => {
        if (counterRef.current) {
          observer.unobserve(counterRef.current);
        }
      };
    }, []);

    useEffect(() => {
      if (hasStarted) {
        const startCounter = () => {
          if (count < end) {
            const timer = setTimeout(() => setCount(prevCount => {
              const newCount = prevCount + increment;
              return newCount >= end ? end : newCount;
            }), 100); // Adjust interval as needed
            return () => clearTimeout(timer);
          }
        };

        const delayTimer = setTimeout(startCounter, delay);
        return () => clearTimeout(delayTimer);
      }
    }, [count, end, increment, delay, hasStarted]);

    return (
      <h5 ref={counterRef}>
        {prefix}{Math.round(count)}{suffix}
      </h5>
    );
  }
  // COUNTER UP WHEN SCROLL TO THE SECTION END

  return (
    <>
      <section className='siteBanner'>
        <AliceCarousel
          items={itemsa}
          responsive={responsivea}
          disableDotsControls
          disableButtonsControls
        />
        <div className='slanding1'>
          <SimpleAnimatedComponent animation="animate__fadeIn">
            <div className='inner'></div>
          </SimpleAnimatedComponent>
        </div>
        <div className='slanding2'>
          <SimpleAnimatedComponent animation="animate__fadeIn">
            <div className='inner'></div>
          </SimpleAnimatedComponent>
        </div>
      </section>

      <section className='pageSubTitle'>
        <div className='container'>
          <div className='data'>
            <div id="dot-1">
              <div className="dot-pattern-ten-six">
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
              </div>
            </div>
              {aboutSlider?.page_title ? (
                <>
                  <h2 dangerouslySetInnerHTML={{ __html: aboutSlider.page_title }} data-aos="fade-up"></h2>
                  <p dangerouslySetInnerHTML={{ __html: aboutSlider.sub_content }} data-aos="fade-up"></p>
                </>
              ) : (
                <p></p>
              )}
          </div>
            <p dangerouslySetInnerHTML={{ __html: aboutSlider.content }} data-aos="fade-up"></p>
        </div>
      </section>

      {/* <section className='pageSubTitle'>
        <div className='container'>
          <div className='data no-padding'>
            <AnimatedComponent animation="animate__fadeInUp">
              <p>We Believe in</p>
              <h2>Ethics</h2>
            </AnimatedComponent>
          </div>
        </div>
      </section> */}

      <section className="icon-box content-image imgheight">
        <div className="container">
          <div className="data">
            {/* <div id="dot-1">
              <div className="dot-pattern-ten-six">
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
              </div>
            </div> */}
            <div className="image" data-aos="fade-up">
                {mission?.mission_image ? (<img src={baseUrl + mission.mission_image} alt={mission.mission_title} />) : (<p></p>)}
            </div>
            <div className="content" data-aos="fade-up">
                {mission?.mission_title ? (<h3>{mission.mission_title}</h3>) : (<p></p>)}
                {mission?.mission_content ? (<p dangerouslySetInnerHTML={{ __html: mission.mission_content }}></p>) : (<p></p>)}
            </div>
          </div>
        </div>
      </section>

      <section className="icon-box image-content imgheight">
        <div className="container">
          <div className="data">
            <div className="image" data-aos="fade-up">
                {mission?.mission_image ? (<img src={baseUrl + mission.vision_image} alt={mission.vision_title} />) : (<p></p>)}
            </div>
            <div className="content" data-aos="fade-up">
                {mission?.vision_title ? (<h3>{mission.vision_title}</h3>) : (<p></p>)}
                {mission?.vision_content ? (<p dangerouslySetInnerHTML={{ __html: mission.vision_content }}></p>) : (<p></p>)}
            </div>
          </div>
        </div>
      </section>

      <section className="icon-box content-image imgheight">
        <div className="container">
          <div className="data">
            <div className="image" data-aos="fade-up">
                {mission?.values_image ? (<img src={baseUrl + mission.values_image} alt={mission.values_title} />) : (<p></p>)}
            </div>
            <div className="content" data-aos="fade-up">
                {mission?.values_title ? (<h3>{mission.values_title}</h3>) : (<p></p>)}
                {mission?.values_content ? (<p dangerouslySetInnerHTML={{ __html: mission.values_content }}></p>) : (<p></p>)}
            </div>
          </div>
        </div>
      </section>

      <section className='pageTitle pb-0'>
        <div className='container'>
          <div className='data no-padding' data-aos="fade-up">
              <div class="dots dot-pattern-ten-six xl">
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
                <section><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></section>
              </div>
              <p>Our</p>
              {impact?.title ? (<h2>{impact.title}</h2>) : (<p></p>)}
          </div>
        </div>
      </section>
      <section className='pageSubTitle pt-3'>
        <div className='container'>
          <div className='data pt-0 no-padding' data-aos="fade-up">
              {impact?.content ? (<small dangerouslySetInnerHTML={{ __html: impact.content }}></small>) : (<p></p>)}
          </div>
        </div>
      </section>

        <section className='impactCarousel'>
          <div className='container'>
            <div className='data' data-aos="fade-up">
              <AliceCarousel
                // mouseTracking
                items={impactItems}
                autoPlay
                autoPlayInterval={5000}
                infinite
                disableButtonsControls
                autoPlayStrategy="none"
                stopOnHover={false}
                responsive={responsive}
              />
            </div>
          </div>
        </section>

      {counterList && counterList.length > 0 && (
        <section className='counter'>
          <div className='container'>
            <div className='data'>
              {counterList.map((list, index) => (
                <>
                    <div className='count' key={index} data-aos="fade-up">
                      <Counter start={0} end={list.counter} duration={2} delay={0} prefix={list.prefix} suffix={list.suffix} />
                      <p>{list.title}</p>
                    </div>
                </>
              ))}
            </div>
          </div>
        </section>
        )}

      {/* <Ceo /> */}

      <Clients />

      <Footer />

      {aboutSlider && (
        <Helmet key={aboutSlider.uuid}>
          {aboutSlider.seo_title !== null && <title>{aboutSlider.seo_title}</title>}
          {aboutSlider.seo_description !== null && (
            <meta name="description" content={aboutSlider.seo_description} />
          )}
          {aboutSlider.seo_keyword !== null && (
            <meta name="keywords" content={aboutSlider.seo_keyword} />
          )}
        </Helmet>
      )}
    </>
  )
}

export default AboutUs